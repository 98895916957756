import React, { useContext, useMemo, useEffect, useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import Wallet from '../../pages/wallet/Wallet'
import History from '../../pages/history/History'
import useAPI from '../../services/apis/useAuthorizedAPI';
import ProfilePage from '../../pages/profile/ProfilePage';
import Impersonate from '../../pages/impersonate/Impersonate';
import TermsUpdate from '../../pages/termsupdate/TermsUpdate'
import './AuthorizedApp.css';
import { setUser as setBugsnagUser, DefaultErrorBoundary, bugsnagClient } from '../../services/bugsnag/bugsnag';
import AuthorizedAppHeader from '../../modules/authorized/header/AuthorizedAppHeader'
import LifetimeEarningsWheel from '../../modules/earningswheel/LifetimeEarningsWheel'
import Cart from '../../context/Cart'
import Order from '../../context/Order'
import Footer from '../../modules/footer/Footer'
import VerifyingEmail from '../../context/VerifyingEmail';

const routes = [
    {
        name: "Wallet",
        path: "/wallet"
    },
    {
        name: "History",
        path: "/history"
    },
    {
        name: "Account",
        path: "/account"
    }
]
routes.wallet = routes[0]
routes.history = routes[1]
routes.account = routes[2]

function AuthorizedApp({profile_id, session_token, admin, logout, newUser}) {
    const user = { _id: profile_id, token: session_token, isAdmin: admin, newUser };
    const {
        api,
        beginImpersonating,
        impersonating,
        endImpersonating
    } = useAPI(profile_id, session_token);

    if (user.isAdmin && !(routes.find(r=>r.path==="/impersonate"))) {
        routes.push({
            name: impersonating ? `Login As (${impersonating})` : 'Login As',
            path: "/impersonate",
            additionalClass: impersonating && 'highlight'
        })
    }

    const order = useContext(Order)
    const cart = useContext(Cart)
    const verifyingEmail = useContext(VerifyingEmail);
    const [emailVerified, setEmailVerified] = useState(false);
    const loadCart = useMemo(()=>((cart && !order) || null) && (() => api.GETpurchase(cart.variants, cart.platform, cart.shop_handle)),[cart,order,api])
    const linkOrder = useMemo(()=>order && (() => api.POSTlinkOrder(order.order_id, order.platform, order.shop_handle)),[order,api])
    const [termsUpdateRequired, setTermsUpdateRequired] = useState(false)

    const _logout = () => {
        api.GETlogout();
        setBugsnagUser(null);
        logout();
    }

    useEffect(() => {
        api.GETprofile().catch(err => {
            switch (err.message) {
                case "TermsUpdateRequired":
                    setTermsUpdateRequired(true)
                    break;

                case "Account deactivated.":
                    _logout()
                    break;
                
                default:
                    bugsnagClient.notify(err)
                    break;
            }
        })
    }, []) // omit api from dep list so that it doesn't call every time api variable changes

    useEffect(() => {
        // TODO need to clear out the verifying email after successfully verifying,
        // otherwise it will attempt to verify every time a user logs in (this session)

        // verify email if present, and then set the flag which will display
        // the success banner in the Wallet page
        if (verifyingEmail) {
            api.POSTverifyEmail(verifyingEmail.email_token)
                .then(()=>setEmailVerified(true))
                .catch((err)=>{
                    if (err.message !== "Token expired."){
                        bugsnagClient.notify(err);
                    }
                });
        }
    }, [verifyingEmail]) // omit api from dep list so that it doesn't call every time api variable changes

    const acceptTermsAgreement = async () => {
        await api.POSTacceptTerms().then(()=>setTermsUpdateRequired(false))
    }

    return (
        <div className="authorized-app">
            <AuthorizedAppHeader routes={routes} logout={_logout} />
            <div className="authorized-content">
                {
                    termsUpdateRequired ? <TermsUpdate acceptTerms={acceptTermsAgreement} />
                    :
                    <Switch>
                        <Redirect exact from="/" to={routes.wallet.path} />
                        <Route path={routes.wallet.path}>
                            <DefaultErrorBoundary>
                                <Wallet
                                    loadInProgress={api.GETInProgressDeals}
                                    loadCart={loadCart}
                                    loadTips={api.GETtips}
                                    shareStory={api.POSTlinkStory}
                                    linkOrder={linkOrder}
                                    newUser={user.newUser}
                                    emailVerified={emailVerified}
                                    footer={Footer()}
                                />
                            </DefaultErrorBoundary>
                        </Route>
                        <Route path={routes.history.path}>
                            <DefaultErrorBoundary>
                                <History
                                    earningsWheel={function EarningsWheel() { return <LifetimeEarningsWheel loadLifetimeEarnings={api.GETlifetimeEarnings} />}}
                                    loadArchivedDeals={api.GETarchivedDeals}
                                    loadLifetimeEarnings={api.GETlifetimeEarnings}
                                    loadTransactions={api.GETtransactions}
                                    footer={Footer()}
                                />
                            </DefaultErrorBoundary>
                        </Route>
                        <Route path={routes.account.path}>
                            <DefaultErrorBoundary>
                                <ProfilePage
                                    getProfile={api.GETprofile}
                                    updateProfile={api.PUTprofile}
                                    deleteProfile={async () => {
                                        await api.DELprofile();
                                        logout();
                                    }}
                                    footer={Footer()}
                                    logout={_logout}
                                />
                            </DefaultErrorBoundary>
                        </Route>
                        {
                            user.isAdmin && (
                                <Route path="/impersonate">
                                    <Impersonate
                                        {...{ 
                                            beginImpersonating,
                                            endImpersonating,
                                            impersonating
                                        }}
                                        />
                                </Route>
                            )
                        }
                        <Redirect to="/" />
                    </Switch>
                }
            </div>
        </div>
    )
}

AuthorizedApp.propTypes = {
    profile_id: PropTypes.string.isRequired,
    session_token: PropTypes.string.isRequired,
    admin: PropTypes.bool.isRequired,
    logout: PropTypes.func.isRequired,
    newUser: PropTypes.bool
}

export default AuthorizedApp;