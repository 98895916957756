export default function cancellable(promise) {
    let hasCancelled = false
    const wrappedPromise = new Promise((resolve, reject) => {
        promise.then(
            val => hasCancelled ? reject({isCancelled: true}) : resolve(val),
            err => hasCancelled ? reject({isCancelled: true}) : reject(err)
        )
    })

    return {
        promise: wrappedPromise,
        cancel() {
            hasCancelled = true
        }
    }
}