import React, { useEffect, useState } from 'react'
import WalletCard from '../../components/walletcard/WalletCard'
import TipJar from '../../modules/tipjar/TipJar'
import PropTypes from 'prop-types'
import './Wallet.css'
import { DefaultErrorBoundary, NoRenderErrorBoundary } from '../../services/bugsnag/bugsnag'
import cancellable from '../../services/helpers/cancellablePromise'

function Wallet({ loadCart, linkOrder, shareStory, loadInProgress, ...props}) {

    const [loading, setLoading] = useState(true)
    const [cart, setCart] = useState(null)
    const [inProgress, setInProgress] = useState(null)
    const [linkedDeals, setLinkedDeals] = useState(null)

    useEffect(() => {
        if (loadCart) {
            const request = cancellable(loadCart())
            
            request
                .promise
                .then((message) => {
                    try {
                        if (message.rebateInfo.variants.length > 0 ) {
                            setCart({
                                brand: message.brand.company,
                                brandHandle: message.brand.igHandle,
                                return_url: message.completePurchaseLink,
                                rebate: message.rebateInfo.rebate,
                                status: "purchase",
                                items: message.rebateInfo.variants,
                                tip: Math.min(message.rewards, message.rebateInfo.price - message.rebateInfo.rebate)
                            })
                        }
                    } catch (err) {
                        console.error(err)
                    }
                    setLoading(null)
                })
                .catch(err => {})
            
            return function cancel() { request.cancel() }
        }
    }, [loadCart])

    useEffect(() => {
        if (!loadCart && linkOrder) {
            const request = cancellable(linkOrder())
            
            request
                .promise
                .then((message) => {
                    try {
                        if (message.length > 0) {
                            setLinkedDeals(message.map(deal => {
                                return {
                                    _id: deal._id,
                                    brand: deal.brand.company,
                                    brandHandle: deal.brand_ig_username,
                                    rebate: deal.rebate,
                                    status: deal.status === "Requested" ? "post" : "cashback",
                                    items: [{
                                        price: deal.price,
                                        title: deal.productName,
                                        rebate: deal.rebate,
                                        imageUrl: deal.content.length ? deal.content[deal.content.length-1].thumbnailUrl : deal.stock.imageUrl,
                                        isugc: !!deal.content.length
                                    }],
                                    deadline: deal.expires,
                                    locked_until: deal.locked_until,
                                    shareStory: (ig_id) => shareStory(deal._id, ig_id)
                                }
                            }))
                        }
                        setLoading(null)
                    } catch (err) {  }
                })
                .catch(err => {})

            return function cancel() { request.cancel() }
        }
    }, [loadCart, linkOrder, shareStory])
    
    useEffect(() => {
        const request = cancellable(loadInProgress())
        
        request
            .promise
            .then((message) => {
                if (message.deals.length > 0) {
                    setInProgress(message.deals.map(deal => {
                        return {
                            _id: deal._id,
                            brand: deal.brand.company,
                            brandHandle: deal.brand_ig_username,
                            rebate: deal.rebate,
                            status: (deal.status === "Requested" || deal.status === "Paused") ? "post" : "cashback",
                            items: [{
                                price: deal.price,
                                title: deal.productName,
                                rebate: deal.rebate,
                                imageUrl: deal.content.length ? deal.content[deal.content.length-1].thumbnailUrl : deal.stock.imageUrl,
                                isugc: !!deal.content.length
                            }],
                            paused: deal.status === "Paused",
                            deadline: deal.expires,
                            locked_until: deal.locked_until,
                            shareStory: (ig_id) => shareStory(deal._id, ig_id)
                        }
                    }))
                }
                setLoading(null)
            })
            .catch(err => { })
        
        return function cancel() { request.cancel() }
    }, [shareStory, loadInProgress])
    
    const deals = []
    for (let deal of [...(inProgress || []), ...(linkedDeals || [])]) {
        if (!deals.find(d=>d._id === deal._id)) deals.push(deal)
    }

    return (
        <div className="wallet-page">
            <div className="wallet">
                <DefaultErrorBoundary>
                    { props.newUser && (
                        <div className="welcome-card" id="new-user-welcome">
                            <h2>Welcome to Guru Club</h2>
                            <h4>Your Instagram is now connected! Shop and post to earn cashback.</h4>
                        </div>)}
                    { props.emailVerified && (
                        <div className="welcome-card">
                            <h2>Email Verified</h2>
                            <h4>Your email address has been verified.</h4>
                        </div>)}
                    { props.newUser && (
                        <div className="progress-card">
                            <h2>Syncing Orders</h2>
                            <h4>We're checking our store directory to find any cashback offers you might be eligible for. Please check back in a few minutes.</h4>
                        </div>)}
                    { loading && <WalletCard loading={true} /> }
                    { cart && <WalletCard {...cart} />}
                    { deals.length > 0 && deals.map((deal,idx)=> <WalletCard key={idx} {...deal} />) }
                    { !loading && !cart && deals.length === 0 && <WalletCard empty={true} />}
                </DefaultErrorBoundary>
                <div className="tablet tip-container">
                    <NoRenderErrorBoundary>
                        <TipJar loadTips={props.loadTips} />
                    </NoRenderErrorBoundary>
                </div>
                <NoRenderErrorBoundary>
                    {props.footer}
                </NoRenderErrorBoundary>
            </div>
            <div className="tablet-hidden tip-container">
                <NoRenderErrorBoundary>
                    <TipJar loadTips={props.loadTips} />
                </NoRenderErrorBoundary>
            </div>
        </div>
    )
}

Wallet.propTypes = {
    loadCart: PropTypes.func,
    linkOrder: PropTypes.func,
    loadInProgress: PropTypes.func.isRequired,
    shareStory: PropTypes.func.isRequired
}

export default Wallet