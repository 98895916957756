import {useState, useEffect} from 'react'
import {useLocation} from 'react-router-dom';
import {bugsnagClient} from '../bugsnag/bugsnag'

export default function useRebatePreview() {
    const { search, pathname } = useLocation()
    const [preview, setPreview] = useState(getPreviewValue(pathname, search))

    useEffect(()=>{
        if (!preview || (preview.pathname !== pathname && preview.search !== search)) {
            const updatedValue = getPreviewValue(pathname, search)
            if (updatedValue) {
                setPreview(updatedValue)
            }
        }
    },[preview, pathname,search])
    
    return preview
}

function getPreviewValue(pathname, search) {
    if (pathname === "/wallet") {
        const queryString = search.substring(1)
        const reqKeys = ["platform", "shop_handle", "variants"]
        const params = queryString
        .split('&')
        .reduce((obj, param) => {
            param = param.split('=');
            if (param[0]) obj[param[0]] = param[1];
            return obj
        }, {});
        
        const queryKeys = Object.keys(params)
        try {
            let hasAllKeys = true
            for (let requiredKey of reqKeys) {
                // unfortunately Array.prototype.includes doesn't seem to work as widely as one might think
                hasAllKeys = hasAllKeys && (queryKeys.indexOf(requiredKey) >= 0)
            }

            if (hasAllKeys) {
                // includes optional return_path key/value
                const { platform, shop_handle, variants, return_path } = params
                return {
                    platform, shop_handle, variants, return_path, pathname, search // incl pathname & searching to allow memoization
                }
            }
        } catch (err) {
            bugsnagClient.notify(err)
        }
    }
}