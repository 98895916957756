import React, { useState, useEffect } from 'react';
import FlatButton from '../../../components/actions/buttons/flat/FlatButton';
import ValidatePassword from '../../../services/helpers/validators/validatePassword';
import './AddPassword.css';

function AddPassword({setAlert, didUpdatePassword}) {
    const passwordRef = React.createRef();
    const [password, setPassword] = useState("");
    const [agreedToTerms, setAgreedToTerms] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState("");

    async function submitForm(e) {
        e.preventDefault()
        setAlert(null)
        try {
            if (password !== confirmPassword) {
                throw new Error("Passwords do not match.")
            }

            // throws if not valid.
            ValidatePassword(password)
            
            if (!agreedToTerms) {
                throw new Error("Please confirm that you agree to our terms of use policy.")
            }

            await didUpdatePassword(password)
        } catch (err) {
            setAlert(err.message)
        }
    }

    // focus on email field
    useEffect(() => {
        if (!password && !confirmPassword && passwordRef.current) {
            passwordRef.current.focus()
        }
    }, [passwordRef, password, confirmPassword]);

    return (
            <form onSubmit={submitForm} id="add-password">
                <h1>Create an Account</h1>
                <div className="inner">
                    <label htmlFor="password">Password</label>
                    <input type="password" ref={passwordRef} value={password} onChange={e=>setPassword(e.target.value)} />
                    <label htmlFor="confirm-password">Confirm Password</label>
                    <input type="password" value={confirmPassword} onChange={e=>setConfirmPassword(e.target.value)} />
                    <div id="tos-div">
                        <input id="tos" type="checkbox" checked={agreedToTerms} onChange={()=>setAgreedToTerms(!agreedToTerms)}/>
                        <label htmlFor="tos">I have read and agree to the guru.club <a target={"_blank"} rel={"noopener noreferrer"} href="https://guru.club/terms-of-service-shoppers/">terms of service</a>, and confirm that I am at least 13 years old and reside in the United States.</label>
                    </div>
                    <FlatButton
                        id="step-2-password"
                        className="primary"
                        type="submit">
                        Continue
                    </FlatButton>
                </div>
                
            </form>
    )
}


export default AddPassword;