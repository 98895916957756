import React from 'react'
import useApiResponse from '../../services/hooks/asyncblock/apiresponse/useApiResponse'
import LDSRing from '../../components/Indicator/LDSRing/LDSRing'
import toUSD from '../../services/helpers/toUSD'
import './LifetimeEarningsWheel.css'

const colorsMap = {
    cashback: "#1242DE",
    tips: "#5D80F0",
    pending: "#B8C8FC"
}

function LifetimeEarningsWheel({loadLifetimeEarnings}) {
    const {
        status
    } = useApiResponse(loadLifetimeEarnings)

    if (status.loading) {
        return <LDSRing />
    } else if (status.result) {

        const { activeTips: tips, totalEarned: cashback, pending } = status.result
        const total = tips + cashback + pending
        const values = {
            cashback, tips, pending
        }
        const sortedTotals = ["cashback", "tips", "pending"].map(label => {
            return { arc: Math.floor((values[label] / total) * 360), color: colorsMap[label] }
        }).sort((a, b) => a.arc - b.arc)
        
        const readableValues = {
            total: toUSD(total),
            cashback: toUSD(cashback),
            pending: toUSD(pending),
            tips: toUSD(tips)
        }

        const smallestRef = (node) => {
            if (node !== null) {
                const arcAngle = 90 - sortedTotals[0].arc
                let styleString = `--arc-angle: ${arcAngle}deg; --border-color: ${sortedTotals[0].color};`
                /*
                    `scaleUp` here and for the next ref can just be set to 60 for ALL cases (not just arcAngle < 0)
                    for reference, it's the smallest scale the enclosing div needs to be to contain the wheel.
                 */
                if (arcAngle < 0) {
                    const scaleUp = 1.0 / Math.cos(arcAngle * Math.PI / 180.0)
                    styleString += ` --scale-up: ${scaleUp};`
                }
                node.style = styleString
            }
        }
        const middleRef = (node) => {
            if (node !== null) {
                const arcAngle = Math.max(-89, 90 - sortedTotals[1].arc); /* if arc becomes -90 (its lowest possible), we need to fix for our arc's capabilities */
                let styleString = `--arc-angle: ${arcAngle}deg; --arc-offset: ${sortedTotals[0].arc + 45}deg; --border-color: ${sortedTotals[1].color};`
                if (arcAngle < 0) {
                    const scaleUp = 1.0 / Math.cos(arcAngle * Math.PI / 180.0)
                    styleString += ` --scale-up: ${scaleUp};`
                }
                node.style = styleString
            }
        }
        const biggestRef = (node) => {
            if (node !== null) {
                node.style.borderColor = sortedTotals[2].color
            }
        }

        return (
            <div className="lifetime-earnings">
                <div ref={biggestRef} className="lifetime-earnings-wheel">
                    <div ref={smallestRef} className="arc" />
                    <div ref={middleRef} className="arc" />
                    <div className="total">
                        <h2 className="cash">{readableValues.total}</h2>
                        <p>Lifetime Earnings</p>
                    </div>
                </div>
                <ul className="lifetime-earnings-legend">
                    {["cashback", "tips", "pending"].map(label => {
                        return (<li key={label}>
                            <div className="marker" style={{background: colorsMap[label]}} />
                            <div>
                                <p className="heavy cash">{readableValues[label]}</p>
                                <p>{label[0].toUpperCase() + label.substring(1)}</p>
                            </div>
                        </li>)
                    })}
                </ul>
            </div>
        )
    } else return null
}

export default LifetimeEarningsWheel