import React, { useState } from 'react';
import './TermsUpdate.css'
import FlatButton from '../../components/actions/buttons/flat/FlatButton'

export default function TermsUpdate({ acceptTerms }) {
    const [agreedToTerms, setAgreedToTerms] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    function _acceptTerms() {
        setIsLoading(true)
        acceptTerms().finally(()=>setIsLoading(false))
    }

    return (
        <div className="terms-update">
            <div>
                <h3>Terms Agreement Required</h3>
                <p>To continue, please <a href="https://guru.club/terms-of-service-shoppers/">review and accept our updated terms of service agreement</a>.</p>
                <input id="tos" type="checkbox" checked={agreedToTerms} onChange={()=>setAgreedToTerms(!agreedToTerms)}/>
                <label htmlFor="tos">I have read and agree to the guru.club <a target={"_blank"} rel={"noopener noreferrer"} href="https://guru.club/terms-of-service-shoppers/">terms of service</a>, and confirm that I am at least 13 years old and reside in the United States.</label>
                <FlatButton loading={isLoading} disabled={!agreedToTerms} style={{margin: '20px auto'}} onClick={_acceptTerms}>Continue</FlatButton>
            </div>
        </div>
    )
}