import React from 'react'
import useApiResponse from '../../services/hooks/asyncblock/apiresponse/useApiResponse'
import LDSRing from '../../components/Indicator/LDSRing/LDSRing'
import redirectExternal from '../../services/helpers/externalLink'
import toUSD from '../../services/helpers/toUSD'
import FlatButton from '../../components/actions/buttons/flat/FlatButton'
import './TipJar.css'

function TipJar({loadTips}) {
    const {
        status,
        localCopy,
    } = useApiResponse(loadTips)

    return (
        <div className="tip-jar">
            { (() => {
                if (status.loading) {
                    return <LDSRing />
                } else if (localCopy) {
                    let userTips
                    let infoText
                    if (localCopy.total > 0 && localCopy.credit.length > 0) {
                        userTips = (<>
                            <div id="total-tips">
                                <h2><span className="cash">{toUSD(localCopy.total)}</span> in Tips</h2>
                                <p className="tip-explain">Tips come in the form of <span style={{fontWeight: "bold"}}>extra</span> cashback on your <span style={{fontStyle: "italic"}}>next</span> purchase/Instagram post with that brand.</p>
                            </div>
                            <ul>
                            {localCopy.credit.map(credit => {
                                return (<div key={credit._id} className="brand-credit">
                                    <p className="tip"><span className="cash">{toUSD(credit.amount)}</span> tip</p>
                                    <p className="brand">from {credit.brand.company}</p>
                                    <FlatButton className="secondary shop-now" onClick={redirectExternal(credit.brand.url)}>Shop Now</FlatButton>
                                </div>)
                            })}
                            </ul>
                        </>)
                    } else {
                        infoText = (
                            <p>Brands can choose to leave you an extra tip on top of your cashback reward. Want to learn how to grab their attention with your creative content?</p>
                        )
                    }
                    return (<>
                        {userTips}
                        <div>
                            <h3>Receive tips from brands for amazing content!</h3>
                            {infoText}
                            <a href="https://guru.club/tag/for-shoppers/">See our guides</a>
                        </div>
                    </>)
                }
            })()}
        </div>
    )
}

export default TipJar