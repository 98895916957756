import React from 'react'
import toUSD from '../../services/helpers/toUSD'
import './DealCard.css'

function DealCard(props) {
    const chevronDisplay = props.moveChevron ? "block" : "none"
    const moveChevronClick = (direction) => {
        if (props.moveChevron) {
            return (e) => { e.preventDefault(); props.moveChevron(direction)}
        }
    }

    const tipSpan = (props.tip||null) && (<> + <span className="cash">{toUSD(props.tip)}</span> Tip</>)

    return (
        <div className="deal-card">
            <div className="deal-card-thumb-container">
                <img className={`deal-card-thumb ${props.isugc?"ugc":""}`} src={props.imageUrl} alt="" />
                <div className="deal-card-thumb-chevron" style={{display: chevronDisplay}}>
                    <button className="clickable-icon"><i className="material-icons md-24" onClick={moveChevronClick(-1)}>keyboard_arrow_left</i></button>
                    <button className="clickable-icon"><i className="material-icons md-24" onClick={moveChevronClick(1)}>keyboard_arrow_right</i></button>
                </div>
            </div>
            <div className="deal-card-info">
                <h2><span className="cash">{toUSD(props.cashback)}</span> Cashback{tipSpan}</h2>
                <p className="deal-card-brand">{props.brand}</p>
                <p className="deal-card-name">{props.name}</p>
                {props.children}
            </div>
        </div>
    )
}

export default DealCard