import useAsyncBlock from '../useAsyncBlock';
import { useEffect, useReducer } from 'react';

/**
 * Specific use case for `useAsyncBlock`:
 * Immediately loads `apiBlock`, and returns a reducer
 * which can be used to make local modifications to the value
 * retrieved from the server.
 * 
 * @param {Promise<any>} apiBlock 
 * @param {Function?} errorTransfomer 
 */
function useApiResponse(apiBlock, errorTransfomer) {
    const { status, load } = useAsyncBlock(apiBlock, undefined, errorTransfomer, true)

    // create reducer
    const [localCopy, dispatch] = useReducer(reducer, null);

    // whenever the async result is reloaded from the server
    // we want to assign our local copy to be an empty object ('unset')
    const _load = (...args) => {
        load(...args);
        dispatch({type: 'unset'});
    }

    // encapsulate 'set'ting and 'unset'ting from user of 
    // hook, return only the functionality of 'setting' values
    const assignLocalCopy = (values) => {
        dispatch({type: 'set', values});
    }

    // finally, tie `localCopy` to the result of the async block, *if*
    // something exists in `result`
    useEffect(() => {
        if (status.result) assignLocalCopy(status.result);
    }, [status.result])

    // localCopy defaults to status.result
    // to account delay between status.loading getting set to false,
    // and status.result being assigned to localCopy
    return { status, load: _load, localCopy: localCopy ? localCopy : status.result, assignLocalCopy};
}

function reducer(state, action) {
    switch (action.type) {
        case 'unset':
            return null;
        case 'set':
            if (typeof(action.values) === 'object') {
                if (typeof(state) === 'object') {
                    return {...state, ...action.values};
                } else {
                    return action.values;
                }
            } else {
                return action.values;
            }
        default:
            throw new Error("Unrecognized type sent to reducer: " + action.type);
    }
    
}

export default useApiResponse;