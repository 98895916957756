import {bugsnagClient} from '../bugsnag/bugsnag'
export default function verifyingEmail() {
    try {
        const { search } = window.location
        const queryString = search.substring(1)
        const params = queryString
            .split('&')
            .reduce((obj, param) => {
                param = param.split('=');
                if (param[0]) obj[param[0]] = param[1];
                return obj
            }, {});
        const queryKeys = Object.keys(params)

        // unfortunately Array.prototype.includes doesn't seem to work as widely as one might think
        if (queryKeys.indexOf("email_token") >= 0) {
            return { email_token: params.email_token };
        }
    } catch (err) {
        bugsnagClient.notify(err);
    }
}