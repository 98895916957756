import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import App from './app/App';
import * as serviceWorker from './serviceWorker';
import { DefaultErrorBoundary } from './services/bugsnag/bugsnag';
import 'react-app-polyfill/ie11'; // https://create-react-app.dev/docs/supported-browsers-features/
import GoogleTagManager from './services/googletagmanager/googletagmanager';

GoogleTagManager.initialize('GTM-T9X7NGB');

ReactDOM.render(
    <DefaultErrorBoundary>
        <Router>
            <App />
        </Router>
    </DefaultErrorBoundary>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
