import React, { useEffect, useState } from 'react'
import cancellable from '../../services/helpers/cancellablePromise'
import PropTypes from 'prop-types'
import LDSRing from '../../components/Indicator/LDSRing/LDSRing'
import toUSD from '../../services/helpers/toUSD'
import isoToDelimitedDate from '../../services/helpers/isoToDelimitedDate'
import PageSelect from '../../components/pageselect/PageSelect'
import TableCount from '../../components/tablecount/TableCount'
import './Transactions.css'

function Transactions({loadTransactions}) {

    const [transactions, setTransactions] = useState([])
    const [totalTranscations, setTotalTransactions] = useState(0) // total existing in the DB
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [pageNum, setPageNum] = useState(1)

    const nPerPage = 20
    

    useEffect(() => {
        const request = cancellable(loadTransactions(pageNum, nPerPage))
        setLoading(true)
        request
            .promise
            .then((message) => {
                const { total, transactions } = message
                setTotalTransactions(total)
                setTransactions(transactions)
            })
            .catch(setError)
            .finally(() => setLoading(false))
        
        return function cleanup() { request.cancel(); setLoading(false); }
    }, [loadTransactions, pageNum])

    if (loading) {
        return <LDSRing />
    } else if (transactions.length === 0) {
        return null
    }

    return (
        <div>
            {loading && <LDSRing />}
            <div style={{overflowX: "auto"}}>
                <TransactionsTable transactions={transactions} />
            </div>
            <TableCount
                itemsName={"Transactions"} 
                pageNum={pageNum}
                nPerPage={nPerPage}
                visibleItemsLength={transactions.length}
                totalItemsLength={totalTranscations}
            />
            <PageSelect
                totalItems={totalTranscations}
                nPerPage={nPerPage}
                setPageNum={setPageNum}
                currentPage={pageNum}
            />
        </div>
    )
}

function TransactionsTable({transactions}) {
    return (
        <table className="transactions-table">
            <thead>
                <th>Date</th>
                <th className="number">Amount</th>
                <th>Type</th>
                {/* <th>Story</th> */}
                <th>Brand</th>
                <th>Product</th>
            </thead>
            {transactions.map(transaction => {
                return (
                    <tr>
                        <td>{isoToDelimitedDate(transaction.date)}</td>
                        <AmountTD amount={transaction.amount} type={transaction.type} />
                        <td>{transaction.type}</td>
                        {/* <td>{transaction.content.length?"View story":"-"}</td> */}
                        <td>{transaction.brand.company}</td>
                        <td>{transaction.productName}</td>
                    </tr>
                )
            })}
        </table>
    )
}

function AmountTD({amount, type, ...props}) {
    let text = toUSD(amount)
    let className = "number"

    switch (type) {
        case "Rejected":
        case "Expired":
            className += " bad"
        // eslint-disable-next-line no-fallthrough
        case "Tip Spent":
            text = "-" + text
            break
        case "Cashback":
        case "Tip Offered":
            className += " cash"
            break
        default:
            break
    }

    return <td {...props} className={className}>{text}</td>
}

Transactions.propTypes = {
    loadTransactions: PropTypes.func.isRequired
}

export default Transactions