import React, { useState, useEffect } from 'react';
import PrimaryButton from '../../components/actions/buttons/primary/PrimaryButton';
import SecondaryButton from '../../components/actions/buttons/secondary/SecondaryButton';
import PropTypes from 'prop-types';
import useAsyncBlock from '../../services/hooks/asyncblock/useAsyncBlock';
import './EditableTextDisplay.css';

/**
 * 
 * children is used as the 'initial value' for editiable text.
 * The component will keep a local copy of the value which will update whenever a new value
 * for `children` is detected
 */
function EditableTextDisplay({ children, update, ...props}) {
    const [value, setValue] = useState(children);
    const [editing, setEditing] = useState(false);
    const { status, load } = useAsyncBlock(update)
    const inputField = React.createRef();

    useEffect(() => {
        setValue(children);
        setEditing(false);
    }, [children, setValue, setEditing]);

    useEffect(() => {
        if (editing) {
            inputField.current.focus();
        }
    }, [editing, inputField]);

    let buttons;
    if (editing) {
        buttons = (
            <div>
                <PrimaryButton
                    loading={status.loading}
                    type="submit"
                    >Save</PrimaryButton>
                <SecondaryButton onClick={()=>{setValue(children); setEditing(false)}}>Cancel</SecondaryButton>
                <div role="alert">{status.error}</div>
            </div>
        )
    } else {
        buttons = (
            <SecondaryButton onClick={()=>setEditing(true)}>Edit</SecondaryButton>
        )
    }

    return (
        <form
            className="editable-display"
            onSubmit={(e)=>{e.preventDefault(); load(value)}}
            >
            <input
                placeholder={children}
                {...props}
                ref={inputField}
                className="editable-display"
                disabled={!editing}
                value={value}
                onChange={(e)=>{setValue(e.target.value)}} />
            {buttons}
        </form>
    )
}

EditableTextDisplay.propTypes = {
    children: PropTypes.string.isRequired,
    update: PropTypes.func.isRequired
}

export default EditableTextDisplay;