import React, {useEffect, useState} from 'react'
import './ProgressTracker.css'

function ProgressTracker({ status }) {
    // convert status to number
    status = (() => {
        switch (status) {
            case "cashback":
                return 2
            case "post":
                return 1
            default:
                return 0
        }
    })()
    function classNameForState(num) {
        let base = "progress-item"
        if (num < status) {
            base += " done"
        } else if (num === status) {
            base += " active"

            if (num === 2) {
                base += " cash"
            }
        }
        
        return base
    }

    return (
        <div className="progress-tracker">
            <div className={classNameForState(0)}>
                <span className="progress-notch" />
                <span className="progress-label">Purchase</span>
            </div>
            <NotchSeparator done={status > 0} />
            <div className={classNameForState(1)}>
                <span className="progress-notch" />
                <span className="progress-label">Post</span>
            </div>
            <NotchSeparator done={status > 1} />
            <div className={classNameForState(2)}>
                <span className="progress-notch" />
                <span className="progress-label">Cashback</span>
            </div>
        </div>
    )
}

function NotchSeparator({done}) {

    function dotCount() {
        const { innerWidth } = window
        if (innerWidth < 640) {
            return 4;
        } else if (innerWidth < 1200) {
            return 8
        } else {
            return 16
        }
    }

    const [dotsCount, setDotsCount] = useState(dotCount())

    useEffect(() => {

        function updateDotsCount() {
            setDotsCount(dotCount())
        }

        window.addEventListener("resize", updateDotsCount)

        return function cleanup() {
            window.removeEventListener("resize", updateDotsCount)
        }
    }, [])


    let separatorDots = []
    for (let i = 0; i < dotsCount; i ++) {
        separatorDots.push((<div key={i} className="progress-separator-dot">•</div>))
    }
    return (
        <div className={"progress-notch-separator"+(done?" done":"")}>
            {separatorDots}
        </div>
    )
}

export default ProgressTracker