import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { notify } from '../../services/bugsnag/bugsnag'
import toUSD from '../../services/helpers/toUSD'
import Cart from '../../context/Cart'
import './RebatePreview.css'

function RebatePreview({previewRebate}) {
    const cart = useContext(Cart)
    const [rebateInfo, setRebateInfo] = useState(null)

    useEffect(() => {
        if (cart) {
            previewRebate(cart.platform, cart.variants, cart.shop_handle).then((res)=> {
                try {
                    const { rebateInfo, brand } = res;
                    if (rebateInfo.variants.length > 0) {
                        setRebateInfo({
                            imageUrl: rebateInfo.variants[0].imageUrl,
                            brandName: brand.company,
                            title: rebateInfo.variants[0].title,
                            rebate: toUSD(rebateInfo.rebate),
                            price: toUSD(rebateInfo.price),
                            discountPrice: toUSD(rebateInfo.price - rebateInfo.rebate),
                            returnUrl: cart.return_path
                        })
                    }
                } catch (err) { notify(err) }
            }).catch((err) => { notify(err) })
        }
    }, [cart, setRebateInfo, previewRebate])

    if (!rebateInfo) return <div className="rebate-preview-phantom" />
    return (
        <div className="rebate-preview">
            <img src={rebateInfo.imageUrl} alt=""/>
            <div>
                <p>Create an account and post to get <span className="cash">{rebateInfo.rebate}</span> Cashback</p>
                <p style={{fontSize: '16px'}}>from {rebateInfo.brandName} • {rebateInfo.title}</p>
                <p><span className="cross" style={{marginRight: '12px'}}>{rebateInfo.price}</span><span className="rebate-preview-cashback"><span className="cash">{rebateInfo.discountPrice}</span> after cashback</span></p>
                { rebateInfo.returnUrl && <a href={rebateInfo.returnUrl}>Return to store</a>}
            </div>
        </div>
    )
}

RebatePreview.propTypes = {
    previewRebate: PropTypes.func.isRequired
}

export default RebatePreview