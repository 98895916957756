import React from 'react'
import './TableCount.css'

function TableCount({itemsName, pageNum, nPerPage, visibleItemsLength, totalItemsLength}) {

    if (totalItemsLength < 1) {
        return null
    }

    const pastTransactionsCount = (pageNum - 1) * nPerPage
    const transactionStart = pastTransactionsCount + 1
    const transactionEnd = pastTransactionsCount + visibleItemsLength

    return (<p className="page-count">{itemsName} {transactionStart} - {transactionEnd} of {totalItemsLength}</p>)

}

export default TableCount