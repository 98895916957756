import React, { useState,  useEffect, useCallback } from 'react';
import VerifyInstagramModule from '../../modules/join/instagram/VetInstagramModule';
import ProveInstagramModule from '../../modules/join/instagram/ProveInstagramModule';
import guruserver from '../../services/apis/guruserver'
import { newSession as saveSignUpProgress, sessionFromStorage as restoreSignUpProgress, clear as removeSignUpProgress } from '../../services/helpers/signupsessionstorage';
import './CreateAccount.css';
import AddPassword from '../../modules/join/addpassword/AddPassword';
import AnchorButton from '../../components/actions/buttons/anchor/AnchorButton'
import { DataLayerPush, dataLayerPush } from '../../services/googletagmanager/googletagmanager'

function CreateAccount({ login, setAlert: setAlertMessage }) {
    const [step, setStep] = useState(0);
    const [goToStep, setGoToStep] = useState(null);

    const [wrapperClassName, setWrapperClassName] = useState("");
    const [storedSignUpSession] = useState(restoreSignUpProgress())
    const [signUpSession, setSignUpSession] = useState({})
    const [token, setToken] = useState(null)

    const _createUser = useCallback(async () => {
        const {
            profile: back_prof,
            signUpSession: upstreamSession
        } = await guruserver.signup.finish(signUpSession._id);

        if (upstreamSession) {
            setSignUpSession(upstreamSession);
            setGoToStep(2);
        } else if (back_prof) {
            login(back_prof._id, token, signUpSession.email);
        }
    }, [token, signUpSession._id, signUpSession.email, login])

    // session ({signUpSession, token}) can come from localStorage
    // or as a response from the guruserver.signup.lookup call
    const setUpSession = useCallback(({signUpSession, token}) => {
        if (signUpSession && token) {
            dataLayerPush({email: signUpSession.email})
            saveSignUpProgress(signUpSession, token)
            setToken(token);
            setSignUpSession(signUpSession);
            guruserver.authenticateAs(token, 'signup:' + signUpSession._id)
        }
    }, [])

    useEffect(() => {
        if (storedSignUpSession && storedSignUpSession.signUpSession) {
            if ((new Date(storedSignUpSession.signUpSession.commentValidUntil)).getTime() < Date.now()) {
                removeSignUpProgress();
            } else {
                setUpSession(storedSignUpSession)
                setGoToStep(2);
            }
        }
    }, [storedSignUpSession, setUpSession])

    async function beginSignUp(email, handle) {
        const res = await guruserver.signup.lookup(email, handle)
        setUpSession(res);
        setGoToStep(1);
    }

    async function setPassword(password) {
        await guruserver.signup.setpassword(signUpSession._id, password)
        setGoToStep(2);
    }

    useEffect(() => {
        if (goToStep != null) {
            setWrapperClassName("transition")
            const timeout = setTimeout(() => {
                setStep(goToStep);
                setGoToStep(null);
                setWrapperClassName("")
            }, 400);
            return function cleanup() { clearTimeout(timeout); }
        }
    }, [goToStep]);

    let submodule;
    switch (step) {
        case 0:
            submodule = (
                <VerifyInstagramModule
                    testHandle={beginSignUp}
                    setAlert={setAlertMessage}
                />);
            break;
        case 1:
            submodule = (
                <AddPassword
                    setAlert={setAlertMessage}
                    didUpdatePassword={setPassword}
                />);
            break;
        case 2:
            submodule = (
                <ProveInstagramModule
                    setAlert={setAlertMessage}
                    createUser={_createUser}
                    signUpSession={signUpSession}
                />);
            break;
        default:
            break;
    }

    return (
        <div className={`create-account ${wrapperClassName}`}>
            {step > 0 && (<AnchorButton onClick={e=> { e.preventDefault(); setGoToStep(step-1)}}>
                Back
            </AnchorButton>)}
            { submodule }
        </div>
    )
}

export default CreateAccount;