import parseApiResponse from '../parsers/apiresponse/parseApiResponse'
import encrypt from '../encrypt/encrypt'

const axios = require('axios')
const api_stage = process.env.REACT_APP_API_STAGE || '2020-02';
const api_mode = process.env.REACT_APP_MODE || 'live';
axios.defaults.baseURL = "https://8qhscmink4.execute-api.us-east-1.amazonaws.com/" + api_stage

const api = {};
((...methods) => {
    for (let method of methods) {
        api[method] = async (...args) => {
            return parseApiResponse(await axios[method](...args));
        }
    }
})('get', 'post', 'put', 'delete')

// let impersonating

export default {
    authenticateAs: function(token, externalreference) {
        axios.defaults.headers.common['userid'] = "none"
        axios.defaults.headers.common['authtoken'] = token
        axios.defaults.headers.common['mode'] = api_mode || 'live'
        axios.defaults.headers.common['externalreference'] = externalreference
        // impersonating = user_id
    },

    impersonate: function(user_id) {
        // impersonating = user_id
    },

    user: {
        login: async function(user, password) {
            const unencrypted = Buffer.from(JSON.stringify({user, password, device: { identifier: 'shopper_app'}}))
            const jweString = await encrypt(unencrypted);
            return await api.post('/3/login', { jwe: jweString });
        },
    },

    signup: {
        lookup: function (handle, email) {
            return api.get(`/3/vet-instagram/${handle}?email=${email}`)
        },

        setpassword: async function(signupid, password) {
            const jweString = await encrypt(Buffer.from(password))
            return await api.put(`/plugins/signup/${signupid}/password`, {
                pass: jweString
            });
        },

        finish: async function(signupid) {
            return await api.post(`/plugins/signup/${signupid}/finish`)
        }
    },

    password: {
        checknonce: function (token) {
            return api.get('/checknonce?token=' + token)
        },

        lost: function (email) {
            return api.get('/lostpass?email=' + email)
        },

        reset: async function (email, token, newPassword) {
            const unencrypted = Buffer.from(JSON.stringify({password: newPassword}))
            const jweString = await encrypt(unencrypted)
    
            return await api.post(`/newpass?email=${email}&token=${token}`, {
                 jwe: jweString 
            })
        }
    },

    rebate: {
        preview: function (platform, variants, shopHandle) {
            return api.get(`/plugins/${platform}/rebates?variants=${variants}&shop=${shopHandle}`)
        }
    },

    email: {
        unsubscribe: function (email) {
            return api.get('/unsubscribe?email=' + email)
        }
    }
}