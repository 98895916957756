import React from 'react';
import Button from '../Button';
import './style.css';

function SecondaryButton(props) {
    return (
        <Button className="secondary" {...props} />
    );
}

export default SecondaryButton;