module.exports = {
    newSession: (signupsession, token) => {
        const signupprogress = JSON.stringify({signupsession, token});
        try {
            localStorage.setItem('signup',signupprogress)
        } catch (err) {
            console.error(err);
        }
    },

    sessionFromStorage: () => {
        try {
            const progress = localStorage.getItem('signup');
            if (progress) {
                const { 
                    signupsession,
                    token
                } = JSON.parse(progress);

                if (signupsession._id && token)
                    return { signUpSession: signupsession, token }
            }
        } catch (err) {
            // localStorage access in browsers with localStorage disabled
            // will throw err
            console.error(err);
        }
    },

    clear: () => {
        try {
            localStorage.removeItem('signup')
        } catch (err) {
            // localStorage access in browsers with localStorage disabled
            // will throw err
            console.error(err);
        }
    }
}