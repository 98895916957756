import React from 'react';
import './AlertModule.css';

function AlertModule(props) {
    if (props.message) {
        return (
            <div role="alert" className="alert-module">
                <i className="material-icons md-24">warning</i>
                <p>{props.message}</p>
            </div>
        )
    } else return null;
}

export default AlertModule;