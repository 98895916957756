import React, { useState } from 'react';
import TitledTemplate from '../../components/templates/titled/TitledTemplate';
import PropTypes from 'prop-types';

function Impersonate({ impersonating, beginImpersonating, endImpersonating }) {
    const [userid, setuserid] = useState("");

    let content;
    if (impersonating) {
        content = (
            <div>
                <p>Logged in as {impersonating}</p>
                <button onClick={endImpersonating}>End</button>
            </div>
        )
    } else {
        const onSubmit = (e) => {
            e.preventDefault();
            beginImpersonating(userid);
        }
        content = (
            <form onSubmit={onSubmit}>
                <input type="text" placeholder="user _id" value={userid} onChange={(e)=>setuserid(e.target.value)}/>
                <input type="submit" value="Go" />
            </form> 
        )
    }

    return (
        <TitledTemplate
            title="Login As"
            >
            {content}
        </TitledTemplate>
    )
}

Impersonate.propTypes = {
    impersonating: PropTypes.string,
    beginImpersonating: PropTypes.func.isRequired,
    endImpersonating: PropTypes.func.isRequired
}

export default Impersonate;