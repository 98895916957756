import React, { useState, useEffect, useContext } from 'react';
import FlatButton from '../../../components/actions/buttons/flat/FlatButton';
import AnchorButton from '../../../components/actions/buttons/anchor/AnchorButton';
import PropTypes from 'prop-types';
import useAsyncBlock from '../../../services/hooks/asyncblock/useAsyncBlock';
import ValidateHandle from '../../../services/helpers/validators/ValidateHandle';
import ValidateEmail from '../../../services/helpers/validators/ValidateEmail'
import './VetInstagramModule.css';
import useBtnRedirect from '../../../services/hooks/useBtnRedirect';
import LinkedEmail from '../../../context/LinkedEmail'

function VetInstagramModule({testHandle, setAlert}) {
    const igRef = React.createRef();

    const linkedEmail = useContext(LinkedEmail)
    const [email, setEmail] = useState(linkedEmail || '')
    const [handle, setHandle] = useState('');

    const [localAlert, setLocalAlert] = useState(null)

    const {
        status: vettingStatus,
        load: vetHandle
    } = useAsyncBlock(testHandle, undefined, vetErrorHandler);

    async function submitForm(e) {
        e.preventDefault()
        if (!ValidateEmail(email)) {
            setLocalAlert("Please provide a valid email.")
        } else if (!ValidateHandle(handle)) {
            setLocalAlert("Please provide a valid Instagram handle.");
        } else {
            setLocalAlert(null)
            vetHandle(handle, email)
        }
    }

    // focus on email field
    useEffect(() => {
        if (!email && !handle && igRef.current) {
            igRef.current.focus()
        }
    }, [igRef, email, handle]);

    setAlert(localAlert || vettingStatus.error)

    return (
        <div className="vet-instagram-module">
            <form onSubmit={submitForm} className="vet-instagram-form">
                <h1>Create an Account</h1>
                <div className="inner">
                    <label htmlFor="ig-handle">Instagram Handle</label>
                    <div className="at-symbol-input">
                        <span id="at-symbol">@</span>
                        <input ref={igRef} id="ig-handle" value={handle} onChange={e=>setHandle(e.target.value)} />
                    </div>
                    <label htmlFor="email">Email</label>
                    <input id="email" value={email} onChange={e=>setEmail(e.target.value)} />
                    <p>Make sure to use the same email you shop with so that we can find deals for you.</p>
                    <div className="horiz-group" style={{justifyContent: "space-between"}}>
                        <FlatButton
                            id="step-1-email"
                            className="primary"
                            loading={vettingStatus.loading}
                            type="submit">
                            Continue
                        </FlatButton>
                        <p>Already have an account? <AnchorButton className="dark" onClick={useBtnRedirect("/login")}>Log In</AnchorButton></p>
                    </div>
                </div>
            </form>
        </div>
    )
}

VetInstagramModule.propTypes = {
    testHandle: PropTypes.func.isRequired
}

function vetErrorHandler(error) {
    switch (error.message) {
        case "DuplicateInstagramError":
            return new Error("This Instagram handle is already in use.");
        case "EmailAlreadyExistsError":
            return new Error("This email address is already in use.")
        case "DoesNotMeetQualificationsError":
            return new Error("Sorry, your account doesn't have enough engagement.");
        case "UserPrivate":
            return new Error("Your profile needs to be public in order to continue.")
        default:
            break;
    }
}

export default VetInstagramModule;