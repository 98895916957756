import React from 'react'
import { NavLink } from 'react-router-dom'
import './TabSelector.css'
import PropTypes from 'prop-types'

function TabSelector(props) {
    return (
        <NavLink
            {...props}
            className="tabselector"
            activeClassName="active">
            {props.children}
        </NavLink>
    )
}

TabSelector.propTypes = {
    to: PropTypes.string.isRequired
}

export default TabSelector