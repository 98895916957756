export default function isoToDelimitedDate(isoString) {
    if (!isValidDate(isoString)) {
        return "01/01/70"
    }
    
    const date = new Date(isoString)

    const month = zeroPad(date.getMonth() + 1)
    const day = zeroPad(date.getDate())
    const year = String(date.getFullYear()).slice(2)

    return `${month}/${day}/${year}`

}

const isValidDate = (isoString) => new Date(isoString).toString() !== 'Invalid Date'

const zeroPad = (num) => "0".repeat(Math.max(0,2 - String(num).length)) + String(num)