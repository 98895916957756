import {useEffect} from 'react'
import { useLocation } from 'react-router-dom'
import ReactGA from 'react-ga'

ReactGA.initialize("UA-162215559-1")

export function setUser(profile_id) {
    ReactGA.set({
        userId: profile_id
    })
}

export function useTracker() {
    const location = useLocation()

    useEffect(() => {
        ReactGA.set({
            page: location.pathname
        })
        ReactGA.pageview(location.pathname + location.search)
    }, [location.pathname, location.search])
}