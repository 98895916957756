import parseApiResponse from '../parsers/apiresponse/parseApiResponse'
import { useState, useMemo } from 'react';

const api_stage = process.env.REACT_APP_API_STAGE || '2020-02';
const api_mode = process.env.REACT_APP_MODE || 'live';

const serviceURL = "https://8qhscmink4.execute-api.us-east-1.amazonaws.com/" + api_stage;
const axios = require('axios');

function useAuthorizedAPI(profile_id, token) {
    const [impersonating, setImpersonating] = useState(null);

    const api = useMemo(() => {
        const headers = {
            authtoken: token,
            userid: profile_id,
            mode: api_mode
        };

        const loggedInAs = impersonating || profile_id;

        const baseURL = serviceURL + "/" + loggedInAs;

        return new AuthorizedAPI(baseURL, headers, loggedInAs)
    }, [token, profile_id, impersonating])

    return {
        api,
        impersonating,
        beginImpersonating: (userid) => setImpersonating(userid),
        endImpersonating: () => setImpersonating(null)
    }
}

/**
 * note: methods should be written with arrow functions,
 * because they are often passed without reference to the instance of the API,
 * which can cause the functions to lose reference to `this`.
 */
class AuthorizedAPI {
    constructor(baseURL, headers, profile_id) {
        this.profile_id = profile_id;
        const api = axios.create({
            baseURL,
            headers
        });

        this.api = { };
        ((...methods) => {
            for (let method of methods) {
                this.api[method] = async (...args) => {
                    return parseApiResponse(await api[method](...args));
                }
            }
        })('get', 'post', 'put', 'delete')
    }

    GETdeals = () => {
        return this.api.get(
            "/viewmydeals"
        )
    }

    GETInProgressDeals = () => {
        return this.api.get(
            "viewmydeals?api_version=shopper_app&status=active"
        )
    }

    GETarchivedDeals = (pageNum = 1, nPerPage = 20) => {
        return this.api.get(
            `viewmydeals?api_version=shopper_app&status=done&page=${pageNum}&nPerPage=${nPerPage}`
        )
    }

    GETtips = () => {
        return this.api.get("credit?api_version=shopper_app")
    }

    POSTlinkStory = (deal_id, ig_id) => {
        return this.api.post(
            "/addcontent/" + deal_id,
            { ig_id }
        )
    }

    POSTlinkOrder = (order_id, platform, shop_handle) => {
        return this.api.post(
            `linkorder?order_id=${order_id}&platform=${platform}&shop_handle=${shop_handle}`
        )
    }

    GETprofile = () => {
        const path = "/profile?id=" + this.profile_id;
        return this.api.get(
            path
        )
    }

    PUTprofile = (update) => {
        return this.api.put(
            "/profile", update
        )
    }

    DELprofile = () => {
        const path = '/profile/' + this.profile_id;
        return this.api.delete(
            path
        );
    }

    GETlogout = () => {
        return this.api.get(
            "/login/shopper_app"
        );
    }

    GETpurchase = (variants, platform, shop_handle) => {
        return this.api.get(
            `/rebates?variants=${variants}&platform=${platform}&shop_handle=${shop_handle}`
        )
    }

    GETlifetimeEarnings = () => {
        return this.api.get('/lifetime-earnings')
    }

    GETtransactions = (page = 1, nPerPage = 20) => {
        return this.api.get(`/transactions?page=${page}&nPerPage=${nPerPage}`)
    }

    POSTacceptTerms = () => {
        return this.api.post('/accept_terms')
    }

    POSTverifyEmail = (email_token) => {
        return this.api.post('/verifyemail?email_token='+email_token);
    }
}

export default useAuthorizedAPI;