import React, { useState } from 'react';
import useAsyncBlock from '../../services/hooks/asyncblock/useAsyncBlock';
import PrimaryButton from '../../components/actions/buttons/primary/PrimaryButton';
import ValidateEmail from '../../services/helpers/validators/ValidateEmail'
import guruserver from '../../services/apis/guruserver'
import './ForgotPass.css'

function ForgotPass(props) {
    const [email, setEmail] = useState('');
    const {
        status: forgotPasswordStatus,
        load: submitEmail
    } = useAsyncBlock(async (email) => {
        if (!ValidateEmail(email)) throw new Error("Please provide a valid email address.");
        return await guruserver.password.lost(email);
    });

    if (forgotPasswordStatus.result === "Email sent.") {
        return (
            <div className="forgot-pass">
                If an account exists at {email}, we will send a message there shortly for further instructions.
            </div>
        )
    } else {

        const focusRef = (node) => {
            if (node !== null && !email) {
                node.focus()
            }
        }
        return (
            <div className="forgot-pass">
                <h3>Lost Password</h3>
                <form onSubmit={(e)=>{e.preventDefault(); submitEmail(email); }}>
                    <label htmlFor="email">Enter the email linked to your account.</label>
                    <input id="email" value={email} ref={focusRef} onChange={(e)=>setEmail(e.target.value)} />
                    <PrimaryButton
                        type="submit"
                        loading={forgotPasswordStatus.loading}
                        error={forgotPasswordStatus.error}
                    >Submit</PrimaryButton>
                </form>
            </div>
        )
    }
}


export default ForgotPass;