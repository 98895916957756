import React, { useState } from 'react';
import useApiResponse from '../../services/hooks/asyncblock/apiresponse/useApiResponse';
import PrimaryButton from '../../components/actions/buttons/primary/PrimaryButton';
import useAsyncBlock from '../../services/hooks/asyncblock/useAsyncBlock';
import validatePassword from '../../services/helpers/validators/validatePassword';
import useBtnRedirect from '../../services/hooks/useBtnRedirect';
import useQueryParams from '../../services/hooks/useQueryParams';
import guruserver from '../../services/apis/guruserver'
import './ResetPass.css'

function ResetPass(props) {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const params = useQueryParams();
    const {
        status: validCodeStatus
    } = useApiResponse(()=>guruserver.password.checknonce(params['token']))

    const {
        status: changePassStatus,
        load: _changePassword
    } = useAsyncBlock(async (pass, confirm) => {
        validatePassword(pass); //throws error on failed validation
        if (pass !== confirm) {
            throw new Error("Values do not match.");
        }
        return await guruserver.password.reset(params['email'], params['token'], pass);
    });

    const goHome = useBtnRedirect('/')
    const goToLogin = useBtnRedirect('/login')

    if (changePassStatus.result === "Password updated.") {
        return (
            <div className="reset-pass">
                <h3>Password Updated.</h3>
                <PrimaryButton
                    onClick={goToLogin}
                >Login</PrimaryButton>
            </div>
        )
    } else if (validCodeStatus.error) {
        return (
            <div className="reset-pass">
                <h3>Token expired.</h3>
                <PrimaryButton
                    onClick={goHome}
                    >Go Home</PrimaryButton>
            </div>
        )
    } else {
        const focusRef = (node) => {
            if (node !== null && !password && !confirmPassword) {
                node.focus()
            }
        }
        return (
            <div className="reset-pass">
                <h3>Reset Password</h3>
                <p>Please enter a new password for your account.</p>
                <form onSubmit={e=>{e.preventDefault(); _changePassword(password, confirmPassword)}}>
                    <label htmlFor="password">Password</label>
                    <input ref={focusRef} type="password" id="password" value={password} onChange={(e)=>setPassword(e.target.value)} />
                    <label htmlFor="confirm-password">Confirm Password</label>
                    <input type="password" id="confirm-password" value={confirmPassword} onChange={(e)=>setConfirmPassword(e.target.value)} />
                    <PrimaryButton
                        loading={changePassStatus.loading}
                        error={changePassStatus.error}
                        type="submit"
                        >Change Password</PrimaryButton>
                </form>
            </div>
        )
    }
}

export default ResetPass;