import React, { useState, useEffect } from 'react';
import useAsyncBlock from '../../services/hooks/asyncblock/useAsyncBlock';
import useBtnRedirect from '../../services/hooks/useBtnRedirect';
import './LoginPage.css';
import FlatButton from '../../components/actions/buttons/flat/FlatButton';
import AnchorButton from '../../components/actions/buttons/anchor/AnchorButton';

function errorHandler(error) {
    if (error.message === "No token.") {
        return new Error("Email/password combination not found.");
    } else return error;
}

function LoginPage({login, setAlert}) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const loginRef = React.createRef()

    useEffect(() => {
        if (!email && !password && loginRef.current) {
            loginRef.current.focus()
        }
    })

    const { status: loginStatus, load: _login } = useAsyncBlock(login, undefined, errorHandler);

    setAlert(loginStatus.error)

    return (
        <div className="login">
            <form className="login-form" onSubmit={(e) => { e.preventDefault(); _login(email, password); }}>
                <h1>Login</h1>
                <div className="inner">
                    <label htmlFor="email-field">
                        Email address
                    </label>
                    <input ref={loginRef} id="email-field" type="email" value={email} onChange={(e)=>setEmail(e.target.value)}/>
                    <label htmlFor="password-field">
                        Password
                    </label>
                    <input id="password-field" type="password" value={password} onChange={(e)=>setPassword(e.target.value)} />
                    <div className="horiz-group">
                        <FlatButton
                            className="primary"
                            type="submit"
                            loading={loginStatus.loading}
                        >Login</FlatButton>
                        <AnchorButton className="dark" onClick={useBtnRedirect("/forgot_pass")}>Forgot Password</AnchorButton>
                    </div>
                </div>
            </form>
            <p>New to Guru Club? <AnchorButton className="dark" onClick={useBtnRedirect("/")}>Create an Account</AnchorButton></p>
        </div>
    )
}

export default LoginPage;