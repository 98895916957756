import React, {useState, useEffect} from 'react';
import { NavLink } from 'react-router-dom'
import VerticalLogo from '../../../media/vectors/GuruClub_logo__Vertical 1.svg'
import FlatButton from '../../../components/actions/buttons/flat/FlatButton'
import AnchorButton from '../../../components/actions/buttons/anchor/AnchorButton'
import { DefaultErrorBoundary } from '../../../services/bugsnag/bugsnag'
import './AuthorizedAppHeader.css';
import { useLocation } from 'react-router-dom'
import redirectExternal from '../../../services/helpers/externalLink'

function AuthorizedAppHeader({routes, logout}) {
    const [showMenuState, setShowMenuState] = useState(0)

    // auto close menu on location change
    const location = useLocation()
    useEffect(() => {
        setShowMenuState(0)
    }, [location])

    const navMenuClass = showMenuState ? "" : "portrait-hidden"
    const icon = showMenuState ? "close" : "dehaze"

    const toggleNavMenu = (e) => {
        e.preventDefault()
        setShowMenuState((showMenuState + 1) % 2)
    }

    return (
        <div id="authorized-app-masthead">
            <div id="top-container">
                <button onClick={toggleNavMenu} id="dehaze" className="portrait"><i className="material-icons md-24 md-light">{icon}</i></button>
                <img src={VerticalLogo} alt="" className="logo" />
            </div>
            <DefaultErrorBoundary>
                <div id="nav" className={navMenuClass}>
                    <ul>
                        {routes.map(route => {
                            return (<li key={route.name}>
                                <NavLink
                                    to={route.path}
                                    className="navlink"
                                    activeClassName="active">
                                    {route.name}
                                </NavLink>
                            </li>)
                        })}
                        <li>
                            <FlatButton onClick={redirectExternal('https://guru.club/featured-brands/')} className="secondary dark" id="shop-now">Shop Now</FlatButton>
                        </li>
                        <li>
                            <AnchorButton onClick={logout} id="logout">Log Out</AnchorButton>
                        </li>
                    </ul>
                </div>
            </DefaultErrorBoundary>
        </div>
    )
}

export default AuthorizedAppHeader