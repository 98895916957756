import React from 'react';
import CoinSVG from '../../media/vectors/Coin.svg'
import PhoneSVG from '../../media/vectors/Phone.svg'
import BagSVG from '../../media/vectors/Bag.svg'
import HorizontalLogo from '../../media/vectors/GuruClub_logo__Horizontal 1.svg'
import './LearnMore.css';

function LearnMore() {
    return (
    <div id="learn-more">
        <div id="info" >
            <img className="landscape" src={HorizontalLogo} alt=""/>
            <div className="horiz-group">
                <img src={BagSVG} alt=""/>
                <div className="vert-group">
                    <h2>1. Shop</h2>
                    <p>Shop your favorite brands and explore trusted products you want. Sign up before, during, or after your purchase.</p>
                </div>
            </div>
            <div className="horiz-group">
                <img src={PhoneSVG} alt=""/>
                <div className="vert-group">
                    <h2>2. Share a Story</h2>
                    <p>After you receive your product, take a photo or video! Share a fun and creative Instagram story and @mention the brand.</p>
                </div>
            </div>
            <div className="horiz-group">
                < img src={CoinSVG} alt=""/>
                <div className="vert-group">
                    <h2 className="gold">3. Get Paid</h2>
                    <p className="gold">Earn cashback when you share your story + gain extra tips for amazing content!</p>
                    <a id="link" href="https://www.guru.club" target="_blank" rel="noopener noreferrer">Learn More About Guru Club</a>
                </div>
            </div>
        </div>
    </div>)
}

export default LearnMore;
