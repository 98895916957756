import React, { useState, useEffect } from 'react';
import useAsyncBlock from '../../../services/hooks/asyncblock/useAsyncBlock';
import './ProveInstagramModule.css';
import FlatButton from '../../../components/actions/buttons/flat/FlatButton';

function ProveInstagramModule({signUpSession, createUser, setAlert}) {
    const commentValidUntil = new Date(signUpSession.commentValidUntil);
    const calcMsLeft = () => Math.max(0,commentValidUntil.getTime() - Date.now());
    const [msLeft, setMsLeft] = useState(calcMsLeft())

    const emoji = signUpSession.comment;
    const [didGoToPost, setDidGoToPost] = useState(false);
    const { status: commentedStatus, load: _createUser } = useAsyncBlock(createUser, undefined, proveErrorHandler);
    const post_shortcode = signUpSession.post_shortcode
    const emojiRef = React.createRef();
    const [state, setState] = useState(0)

    // handle comment time left countdown
    const minsLeft = Math.floor(msLeft / 60000);
    const secsLeft = Math.floor(msLeft / 1000) % 60;
    useEffect(() => {
        if (msLeft > 0) {
            let timeout = setTimeout(() => {
                setMsLeft(calcMsLeft())
            }, 1000);
            return function cleanup(){ clearTimeout(timeout) }
        }
    });

    const commentMinPostTime = new Date(signUpSession.updatedAt)

    function copy(e) {
        e.preventDefault()
        emojiRef.current.select();
        document.execCommand('copy');
        if (state < 1) setState(1)
    }

    function goToPost(e) {
        e.preventDefault()
        setDidGoToPost(true)
        window.open("https://www.instagram.com/p/" + post_shortcode)
        if (state < 2) setState(2)
    }

    useEffect(() => {
        function handleVisibilityChange() {
            if (!document.hidden && didGoToPost) {
                setDidGoToPost(false)
               _createUser(emoji);
            }
        }

        document.addEventListener("visibilitychange", handleVisibilityChange, false);

        return function() {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
        }
    }, [_createUser, setDidGoToPost, didGoToPost, emoji]);


    setAlert(commentedStatus.error)

    const secondaryUnlessState = (num) => state!==num?"secondary":""

    return (
        <form id="verify-instagram">
            <div className="vert-group">
                <h1>Link Your Instagram To Finish</h1>
                <p>We need to make sure this is your authentic Instagram account so you can start posting stories for cashback.</p>
            </div>
            <div className="vert-group">
                <label htmlFor="to-copy">1. Copy this emoji</label>
                <div className="horiz-group">
                    <input readOnly ref={emojiRef} id="to-copy" value={emoji} />
                    <FlatButton className={`${secondaryUnlessState(0)} dark`} id="copy-button" onClick={copy}>Copy</FlatButton>
                </div>
            </div>
            <div className="vert-group">
                <label>2. Comment the emoji on our post ({minsLeft} mins, {secsLeft} seconds left to comment. Comment must be created after {commentMinPostTime.toLocaleTimeString('en-US')}.)</label>
                <FlatButton className={`${secondaryUnlessState(1)} dark`} id="to-post" onClick={goToPost}>Go To Post</FlatButton>
            </div>
            <div className="vert-group">
                <label>3. Let us know</label>
                <FlatButton className={`${secondaryUnlessState(2)} dark`} id="check-comment" loading={didGoToPost || commentedStatus.loading} onClick={e=>{e.preventDefault(); _createUser(emoji)}}>I Posted</FlatButton>
            </div>
        </form>
    )
}

function proveErrorHandler(error) {
    switch (error.message) {
        case "NoCommentError":
            return new Error("Your comment could not be found.");
        case "HighVolumeError":
            return new Error("We are currently experiencing a high volume of traffic. Please wait a few minutes, then try again.")
        default:
            break;
    }
}


export default ProveInstagramModule;