import React from 'react'
import PropTypes from 'prop-types'
import './MessageDisplay.css'

function MessageDisplay({message}) {
    return (
        <div className="message-display">
            <h3>{message}</h3>
        </div>
    )
}

MessageDisplay.propTypes = {
    message: PropTypes.string.isRequired
}

export default MessageDisplay