import React from 'react';
import LDSRing from '../../Indicator/LDSRing/LDSRing';
import './style.css';

function Button(props) {
    const { loading, error} = props;
    const passedProps = { ...props, ...{ loading: undefined, error: undefined }}
        return (
            <div className="button-wrapper">
                <button {...passedProps}>
                    <div className="loading-container">{loading && <LDSRing />}</div>
                    {props.children}
                </button>
                <div className="button-errors" role="alert">{error}</div>
            </div>
        )
    
}

export default Button;