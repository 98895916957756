import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ForgotPass from '../../pages/forgot_pass/ForgotPass';
import ResetPass from '../../pages/reset_pass/ResetPass';
import Unsubscribe from '../../pages/unsubscribe/Unsubscribe'
import './UnauthorizedApp.css'
import Portal from '../../pages/portal/Portal'

function UnauthorizedApp({ login }) {
    return (
        <div className="unauthorized-app">
            <Switch>
                <Route path="/forgot_pass">
                    <ForgotPass />
                </Route>
                <Route path="/reset_pass">
                    <ResetPass />
                </Route>
                <Route path="/unsubscribe">
                    <Unsubscribe />
                </Route>
                <Route>
                    <Portal
                        login={login}
                    />
                </Route>
            </Switch>
        </div>
    )
}

export default UnauthorizedApp;