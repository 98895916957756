import {useLocation} from 'react-router-dom';

export default function useQueryParams() {
    const queryString = useLocation().search.substring(1);
    const params = queryString
      .split('&')
      .reduce((obj, param) => {
        param = param.split('=');
        obj[param[0]] = param[1];
        return obj
      }, {});
    return params;
}