import React, { useState, useEffect } from 'react';
import AuthorizedApp from './authorized/AuthorizedApp';
import UnauthorizedApp from './unauthorized/UnauthorizedApp';
import useSession from '../services/hooks/useSession';
import usePersistentLocation from '../services/hooks/usePersistentLocation';
import PersistedLocation from '../context/PersistedLocation';
import useRebatePreview from '../services/hooks/useRebatePreview'
import Cart from '../context/Cart'
import Order from '../context/Order'
import LinkedEmail from '../context/LinkedEmail'
import VerifyingEmail from '../context/VerifyingEmail';
import useQueryParams from '../services/hooks/useQueryParams'
import getLinkedOrder from '../services/helpers/linkedOrder'
import getVerifyingEmail from '../services/helpers/verifyingEmail';
import './MaterialIcons.css';
import './App.css';
import { DefaultErrorBoundary, setUser as setBugsnagUser } from '../services/bugsnag/bugsnag'
import { setUser as setGAUser, useTracker } from '../services/googleanalytics/GoogleAnalytics'
import ScrollRestoration from '../services/helpers/ScrollRestoration'
import { dataLayerPush } from '../services/googletagmanager/googletagmanager';
import useUTMTags from '../services/hooks/useUTMTags';

function App() {
  const session = useSession();
  const persistedLocation = usePersistentLocation();
  const [linkedOrder] = useState(getLinkedOrder());
  const [verifyingEmail] = useState(getVerifyingEmail());
  const rebatePreview = useRebatePreview()
  const [linkedEmail] = useState(useQueryParams().email)
  const [{source, brand}] = useState(useUTMTags()); // useState to retain one value, even after query params change

  useEffect(() => {
    dataLayerPush({source, brand})
  }, [source, brand])

  useTracker()

  useEffect(() => {
    dataLayerPush({ email: session.email })
    setGAUser(session.profile_id)
    setBugsnagUser(session.profile_id, session.email)
  }, [session])

  return (
    <div className="App">
      <link href="https://fonts.googleapis.com/css?family=Open+Sans|Roboto:300,400,500|Montserrat:400,600,700&display=swap" rel="stylesheet" /> 
      <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
      <div id="App-main">
        <DefaultErrorBoundary>
          <ScrollRestoration />
          <VerifyingEmail.Provider value={verifyingEmail}>
            <LinkedEmail.Provider value={linkedEmail}>
              <Order.Provider value={linkedOrder}>
                <Cart.Provider value={rebatePreview}>
                  <PersistedLocation.Provider value={persistedLocation} >
                    {
                      session.loggedIn ? (
                        <AuthorizedApp
                          profile_id={session.profile_id}
                          session_token={session.token}
                          newUser={session.newUser}
                          admin={session.admin}
                          logout={session.logout}
                        />
                        ) : (
                        <UnauthorizedApp
                          login={session.login}
                        />
                        )
                    }
                  </PersistedLocation.Provider>
                </Cart.Provider>
              </Order.Provider>
            </LinkedEmail.Provider>
          </VerifyingEmail.Provider>
        </DefaultErrorBoundary>
      </div>
    </div>
  );
}

export default App;
