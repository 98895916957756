import React from 'react';
import './FlatButton.css'
import LDSRing from '../../../Indicator/LDSRing/LDSRing'

function FlatButton(props) {
    return (
        <button {...props} loading={props.loading?1:0} className={`flat-button ${props.loading?"loading ":""}${props.className}`}>
            <>
                {props.loading && <LDSRing />}
                <span>{props.children}</span>
                {props.tooltip && <span className="tooltip">{props.tooltip}</span>}
            </>
        </button>
    )
}

export default FlatButton;