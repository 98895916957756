import React from 'react';
import PropTypes from 'prop-types';

function Error({ retry, message }) {
    const retrySpan = retry && ( <button onClick={retry}>Retry?</button>);
    return (
        <div className="error">
            {message}{retrySpan}
        </div>
    )
}

Error.defaultProps = {
    message: "An error occured."
};

Error.propTypes = {
    message: PropTypes.string.isRequired,
    retry: PropTypes.func
}

export default Error;