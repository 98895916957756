import React, { useContext, useState, useEffect } from 'react';
import { Switch, Route, useHistory, Redirect } from 'react-router-dom';
import LearnMore from '../learn_more/LearnMore'
import CreateAccount from '../create_account/CreateAccount'
import LoginPage from '../login/LoginPage'
import AlertModule from '../../modules/alert/AlertModule';
import PersistedLocation from '../../context/PersistedLocation';
import './Portal.css'
import RebatePreview from '../../components/rebate_preview/RebatePreview';
import guruserver from '../../services/apis/guruserver'
import { DefaultErrorBoundary } from '../../services/bugsnag/bugsnag'
import PortraitLogo from '../../media/vectors/GuruClub_logo__Vertical 1.svg'
import VerifyingEmail from '../../context/VerifyingEmail'

function Portal({login}) {
	const [alertMessage, setAlertMessage] = useState(null);
	const verifyingEmail = useContext(VerifyingEmail);
	const verifyEmailMessage = verifyingEmail && "Please login to finish verifying your email address.";

	const persistedLocation = useContext(PersistedLocation);
    const history = useHistory();

	const _login = async (_id,token,email,admin,newUser=false) => {
        login(_id, token, email, admin, newUser);
        // revive state from initial arrival to page
        const revivedState = persistedLocation.pathname ? persistedLocation : { pathname: "/" };
        history.push(revivedState);
	}

	return (
		<div className="portal">
			<div id="portal-left-side">
					<DefaultErrorBoundary>
						<img className="logo portrait" alt="" src={PortraitLogo} />
						<AlertModule message={alertMessage || verifyEmailMessage}/>
						<div id="portal-interact-div" className="buffered">
							<Switch>
								<Route exact path="/">
									<CreateAccount
										setAlert={setAlertMessage}
										login={(_id,token,email)=>_login(_id,token,email,false,true)}
									/>
								</Route>
								<Route path={`/login`}>
									<LoginPage
										login={async (user,pass)=>{
											const {_id,token,admin} = await guruserver.user.login(user,pass)
											await _login(_id,token,user,admin,false)
										}}
										setAlert={setAlertMessage}
									/>
								</Route>
								<Redirect to="/" />
							</Switch>
						</div>
					</DefaultErrorBoundary>
				<RebatePreview previewRebate={guruserver.rebate.preview} />
			</div>
			<LearnMore />
		</div>
	)
}

export default Portal