import React, { useState, useEffect } from 'react'
import DealCard from '../../components/dealcard/DealCard'
import DateSpan from '../../components/datespan/DateSpan'
import cancellable from '../../services/helpers/cancellablePromise'
import './Stories.css'
import LDSRing from '../../components/Indicator/LDSRing/LDSRing'
import PageSelect from '../../components/pageselect/PageSelect'
import TableCount from '../../components/tablecount/TableCount'


function Stories({loadDeals}) {
    const [deals, setDeals] = useState([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    const nPerPage = 20
    const [totalDeals, setTotalDeals] = useState(0)
    const [pageNum, setPageNum] = useState(1)

    useEffect(() => {
        setLoading(true)
        const request = cancellable(loadDeals(pageNum, nPerPage))
        request
            .promise
            .then((message) => {
                const { deals, total } = message
                setDeals(deals)
                setTotalDeals(total)
            })
            .catch(setError)
            .finally(() => {
                setLoading(false)
            })
        
        return function cleanup() { request.cancel(); setLoading(false) }
    }, [loadDeals, pageNum])

    return (
        <div>
            <div className="history-stories">
                {(()=>{
                    if (loading) {
                        return <LDSRing />
                    } else if (deals.length === 0) {
                        return (null)
                    } else {
                        return deals.map((deal,idx) => {
                            return (
                                <div className="history-card" key={idx}>
                                    <DealCard
                                        imageUrl={deal.content.length && deal.content[deal.content.length-1].thumbnailUrl}
                                        isugc={deal.content.length > 0}
                                        cashback={deal.rebate}
                                        brand={deal.brand.company}
                                        name={deal.productName}
                                        tip={deal.auto_tip||0 + deal.manual_tip||0}
                                    >
                                        <p>Earned <DateSpan datetime={deal.closed} /></p>
                                    </DealCard>
                                </div>
                            )
                        })
                    }
                })()}
            </div>
            <TableCount
                itemsName={"Deals"}
                pageNum={pageNum}
                nPerPage={nPerPage}
                visibleItemsLength={deals.length}
                totalItemsLength={totalDeals}
            />
            <PageSelect
                totalItems={totalDeals}
                nPerPage={nPerPage}
                setPageNum={setPageNum}
                currentPage={pageNum}
            />
        </div>
    )
}

export default Stories