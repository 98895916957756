import { bugsnagClient } from '../../services/bugsnag/bugsnag'

export default function linkedOrder() {
    const { search, pathname } = window.location
    bugsnagClient.leaveBreadcrumb("Detecting linked order", {
        search,
        pathname
    })
    if (pathname === "/wallet") {
        const queryString = search.substring(1)
        const reqKeys = ["platform", "shop_handle", "order_id"]
        const params = queryString
        .split('&')
        .reduce((obj, param) => {
            param = param.split('=');
            if (param[0]) obj[param[0]] = param[1];
            return obj
        }, {});
        
        const queryKeys = Object.keys(params)
        try {
            let hasAllKeys = true
            for (let requiredKey of reqKeys) {
                // unfortunately Array.prototype.includes doesn't seem to work as widely as one might think
                hasAllKeys = hasAllKeys && (queryKeys.indexOf(requiredKey) >= 0)
            }

            if (hasAllKeys) {
                // includes optional return_path key/value
                const { platform, shop_handle, order_id } = params
                return {
                    platform, shop_handle, order_id
                }
            }
        } catch (err) {
            bugsnagClient.notify(err)
        }
    }
}