import React from 'react'
import instagram from '../../media/pngs/cib_instagram.png'
import linkedin from '../../media/pngs/linkedin.png'
import youtube from '../../media/pngs/youtube.png'
import './Footer.css'

function Footer() {
    return (
        <div className="footer">
            <div className="footer-main-links">
                <a href="https://guru.club">About Guru Club</a>
                <a href="https://guru.club/featured-brands/">Store Directory</a>
                <a href="https://guru.club/for-shoppers/faqs/">Customer Service</a>
                <a href="https://guru.club/contact/">Contact</a>
                <a href="https://guru.club/privacy-policy/">Privacy Policy</a>
                <a href="https://guru.club/terms-of-service-shoppers/">Terms of Service</a>
            </div>
            <div className="footer-social-links">
                <a href="https://instagram.com/shopguruclub"><img src={instagram} alt="Link to Shop Guru Club on Instagram"/></a>
                <a href="https://www.youtube.com/channel/UCD6Jfj0wnigEUAzO0CF09lA"><img src={youtube} alt="Link to Guru Club on YouTube"/></a>
                <a href="https://www.linkedin.com/company/guru-club-llc/"><img src={linkedin} alt="Link to Guru Club on LinkedIn"/></a>
            </div>
            © 2020 All rights reserved
        </div>
    )
}

export default Footer