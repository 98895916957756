import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';


function usePersistentLocation() {
    const { search, pathname } = useLocation();
    const [location, setLocation] = useState({});
    const [reload, setReload] = useState(true);
    const [clear, setClear] = useState(false);

    // at initial usage, and anytime persist() is called,
    // set persisted location state var as current values
    // of react-router-dom location.search & location.pathname
    useEffect(() => {
        if (reload) {
            setReload(false);
            setLocation({search, pathname});
        }
    }, [reload, setReload, setLocation, search, pathname])

    // clear the persisted location upon request
    useEffect(() => {
        if (clear) {
            setClear(false);
            setLocation({});
        }
    }, [setLocation, clear, setClear])

    // return persisted pathname & search string, w/
    // function to persist current values
    return {
        pathname: location.pathname,
        search: location.search,
        persist: () => { setReload(true); },
        clear: () => { setClear(true); }
    }
}

export default usePersistentLocation;