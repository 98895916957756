import React from 'react';
import PropTypes from 'prop-types';
import './TitledTemplate.css';

function TitledTemplate({title, description, children}) {
    return (
        <div className="titled-template">
            <h3 className="title">{title}</h3>
            <p className="description">{description}</p>
            <div className="children">
                { children }
            </div>
        </div>
    )
}

TitledTemplate.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string
}

export default TitledTemplate;