import React, { useState } from 'react'
import LDSRing from '../Indicator/LDSRing/LDSRing'
import redirectExternal from '../../services/helpers/externalLink'
import useAsyncBlock from '../../services/hooks/asyncblock/useAsyncBlock'
import DealCard from '../dealcard/DealCard'
import ProgressTracker from './progresstracker/ProgressTracker'
import FlatButton from '../../components/actions/buttons/flat/FlatButton'
import toUSD from '../../services/helpers/toUSD'
import AlertModule from '../../modules/alert/AlertModule'
import DateSpan from '../../components/datespan/DateSpan'
import './WalletCard.css'
import RecentlyOrderedLock from '../../services/helpers/recentlyOrderedLock'

function dealErrorHandler(error) {
    switch (error.message) {
        case "AlreadyShared":
            return new Error("This story item has already been used for another cashback offer.");
        case "InvalidTimestamp":
            return new Error("The timestamp for this story item is invalid for this order.");
        case "NotFoundError":
            return new Error("Your story could not be found. Please make sure you tagged the brand.");
        default:
            return error
    }
}

function WalletCard({loading, empty, items, tip, brandHandle, return_url, rebate, status: propStatus, paused, deadline, locked_until, shareStory: propShareStory}) {
    const [imageUrls, setImageUrls] = useState([])
    const [imageUrlIdx, setImageUrlIdx] = useState(0)
    const [isRedirecting, setIsRedirecting] = useState(false)
    const recentlyOrderedLock = RecentlyOrderedLock(locked_until);
    const [status, setStatus] = useState(propStatus)
    const {
        load: shareStory,
        status: storySharing
    } = useAsyncBlock(async (e) => {
        // e because our story sharing block is triggered by a button
        e.preventDefault()
        const ig_id = (imageUrls.length > 0) && imageUrls[imageUrlIdx].ig_id
        const response = await propShareStory(ig_id)

        if (Array.isArray(response)) {
            // response was an array of eligible stories to share. This user has more than 1.
            setImageUrlIdx(0)
            setImageUrls(response)
        } else if (typeof(response) === "string") {
            setImageUrlIdx(0)
            setImageUrls([{thumbnail_url: response}])
            setStatus("cashback")
        } else throw new Error("Unexpected response from server: " + response)
    }, null, dealErrorHandler)

    const moveChevron = imageUrls.length > 1 && ((direction) => {
        let newIdx = (imageUrlIdx + direction) % imageUrls.length
        if (newIdx < 0) newIdx = imageUrls.length - 1
        setImageUrlIdx(newIdx)
    })

    const actionRow = (() => {
        switch (status) {
            case "purchase":
                return (
                    <div className="action-row">
                        <FlatButton
                            loading={isRedirecting}
                            onClick={e=> { redirectExternal(return_url)(e); setIsRedirecting(true)}}
                        >Back to Store</FlatButton>
                        <p>Pay full price now, get cashback when you mention {linkHandle(brandHandle)} in a story about your purchase.</p>
                    </div>
                )
            case "post":
                // if the item was purchased within some time frame (defined in backend)
                // (and deal.locked_until is there to show it), 
                // or if deal is in a "Paused" state (both can be true)
                // block the post

                let disabledMessage;
                if (paused) {
                    disabledMessage = "This merchant has temporarily paused all Guru Club deals."
                } else if (recentlyOrderedLock) {
                    disabledMessage = `Please wait for the item to arrive! Deal unlocks ${recentlyOrderedLock}.`
                }

                return (
                    <div>
                        <AlertModule message={storySharing.error} />
                        <div className="action-row">
                            <FlatButton
                                loading={storySharing.loading}
                                onClick={shareStory}
                                error={storySharing.error}
                                disabled={!!disabledMessage}
                                tooltip={disabledMessage}
                            >{imageUrls.length > 1 ? "Choose Item" : "I Posted"}</FlatButton>
                            <p>{imageUrls.length > 1 ? (<>Please select an item for your review.</>) : (<>Once your purchase arrives in the mail, feature it and mention {linkHandle(brandHandle)} in a story by <DateSpan datetime={deadline} />.</>)}</p>
                        </div>
                    </div>
                )
            case "cashback":
                return (
                    <div>
                        {storySharing.error}
                        <div className="action-row">
                            <FlatButton
                                loading={storySharing.loading}
                                onClick={shareStory}
                                error={storySharing.error}
                            >{imageUrls.length > 1 ? "Choose Item" : "Change Story"}</FlatButton>
                            <p>{imageUrls.length > 1 ? "Please select an item for your review." : "Pending Approval"}</p>
                        </div>
                    </div>
                )
            default:
                return null
        }
    })()

    return (
        <div className="wallet-card">
            { (() => {
                if (loading) {
                    return <LDSRing />
                } else if (empty) {
                    return (
                        <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginTop: "25px"}}>
                            <h4 className="Cart_Title">No active cashback purchases</h4>
                            <p>Shop now to start earning cashback.</p>
                            <FlatButton onClick={redirectExternal("https://guru.club/featured-brands/")}>Shop Now</FlatButton>
                        </div>
                    )
                } else {
                    return (
                        <>
                            <ProgressTracker status={status} />
                            { (() => {
                                if (items.length < 1) {
                                    throw new Error("WalletCard items length should be greater than 0.")
                                } else if (items.length === 1) {
                                    const [item] = items
                                    const imageUrl = imageUrls.length > 0 ? imageUrls[imageUrlIdx].thumbnail_url : item.imageUrl
                                    return (
                                        <DealCard
                                            name={item.title}
                                            cashback={item.rebate}
                                            imageUrl={imageUrl}
                                            brand={linkHandle(brandHandle)}
                                            moveChevron={moveChevron}
                                            isugc={item.isugc || !!imageUrls.length}
                                            tip={tip}
                                        >
                                            <DiscountPriceRow price={item.price} rebate={item.rebate} tip={tip} />
                                            {actionRow}
                                        </DealCard>
                                    )
                                } else if (items.length > 1) {
                                    // multi-product cart
                                    const tipSpan = (tip||null) && (<> + <span className="cash">{toUSD(tip)}</span> Tip</>)
                                    const headerLabel = <h2>{cashbackSpan(rebate)} Cashback from {linkHandle(brandHandle)}{tipSpan}</h2>
                                    return (
                                        <>
                                            {headerLabel}
                                            {items.map((item,idx) => {
                                                return (
                                                    <div style={{marginTop:'12px'}} key={{idx}}>
                                                        <DealCard
                                                            moveChevron={moveChevron}
                                                            name={item.title}
                                                            cashback={item.rebate}
                                                            imageUrl={item.imageUrl}
                                                        >
                                                            <DiscountPriceRow price={item.price} rebate={item.rebate} />
                                                        </DealCard>
                                                    </div>
                                                )
                                            })}
                                            {headerLabel}
                                            {actionRow}
                                        </>
                                    )
                                }
                            })()}
                        </>
                    )
                }
            })()
            }
        </div>
    )
}

function DiscountPriceRow({price, rebate, tip}) {
    let discountPrice = price-rebate
    let disclaimerText = "after cashback"
    if (tip) {
        discountPrice -= Math.min(tip,discountPrice)
        disclaimerText += " and tip"
    }

    return (
        <p style={{fontSize: '16px', lineHeight: '19px'}}>
            <span className="cross">{toUSD(price)}</span> <span style={{fontWeight: 500, marginLeft: '20px'}}>
                <span className="cash">{toUSD(discountPrice)}</span> {disclaimerText}
            </span>
        </p>)
}

const linkHandle = (handle) => <a href={`https://instagram.com/${handle}`} target="_blank" rel="noopener noreferrer">@{handle}</a>

const cashbackSpan = (pennies) => <span className="cash">{toUSD(pennies)}</span>

export default WalletCard