import React from 'react';
import Button from '../Button';
import './style.css';

function DestructiveButton(props) {
    return (
        <Button className="destructive" {...props} />
    );
}

export default DestructiveButton;