import React from 'react';
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';
import { version as appVersion } from '../../../package.json';
import MessageDisplay from '../../modules/messagedisplay/MessageDisplay'

export const bugsnagClient = bugsnag({
    apiKey: '86234446aab4ead6d2c412106d6631b6',
    appVersion,
    beforeSend: function(report) {
        report.app.releaseStage = (/^(https:\/\/\w+\.ngrok)|(http:\/\/localhost)/.test(window.location.href)) ?
            'development' : 'production';
        /*if (report.app.releaseStage === 'development') {
            // don't report development errors
            return false;
        }*/
    }
});

bugsnagClient.use(bugsnagReact, React);

const ErrorBoundary = bugsnagClient.getPlugin('react');
export function DefaultErrorBoundary({children}) {
    return <ErrorBoundary FallbackComponent={()=><MessageDisplay message="Sorry, an unexpected error occurred." />}>{children}</ErrorBoundary>
}

export function NoRenderErrorBoundary({children}) {
    return <ErrorBoundary FallbackComponent={()=>null}>{children}</ErrorBoundary>
}

export function setUser(id, email, username) {
    bugsnagClient.user = {
        id, email, username
    };
}
export function notify(error) {
    bugsnagClient.notify(error)
}