import React from 'react';
import Button from '../Button';
import './style.css';

function PrimaryButton(props) {
    return (
        <Button className="primary" {...props} />
    );
}

export default PrimaryButton;