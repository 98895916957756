import { useState, useEffect } from 'react';

function useAsyncBlock(asyncBlock, resultTransformer, errorTransformer, loadImmediately = false) {
    const [status, setStatus] = useState({
        loading: loadImmediately,
        error: null,
        result: null
    });
    const [reload, setReload] = useState(loadImmediately);
    const [args, setArgs] = useState([]);

    useEffect(() => {
        function setError(error) {
            error = (errorTransformer && errorTransformer(error)) || error;
            setStatus({
                loading: false,
                result: null,
                error: error.message // TODO important, please note
            });
        }
        if (reload) {
            setStatus({
                loading: true,
                error: null,
                result: null
            });
            setReload(false);
            asyncBlock(...args).then(function(result) {
                if (resultTransformer) {
                    try {
                        result = resultTransformer(result);
                    } catch (error) { setError(error); }
                }
                setStatus({
                    loading: false,
                    result,
                    error: null
                });
            }).catch(setError)
        }
    }, [setStatus, reload, asyncBlock, errorTransformer, resultTransformer, args]);

    return { status, load: (...args) => { setArgs(args); setReload(true); }};
}

export default useAsyncBlock;