import React from 'react'
import AnchorButton from '../actions/buttons/anchor/AnchorButton'
import PropTypes from 'prop-types'
import './PageSelect.css'

function PageSelect({totalItems, nPerPage, setPageNum, currentPage}) {
    
    if (totalItems < 1) {
        return null
    }

    const numPages = Math.ceil(totalItems / nPerPage)
    const pageNums = []
    for (let i = 1; i <= numPages; i++) pageNums.push(i)
    const pageNumList = pageNums.map(pageNum => {
        const action = (e) => {
            e.preventDefault()
            setPageNum(pageNum)
        }
        let className = "page-num"
        className += pageNum===currentPage?" highlight":""
        return (<AnchorButton className={className} key={pageNum} onClick={action}>{pageNum}</AnchorButton>)
    })

    const goPrev = (e) => {
        e.preventDefault()
        setPageNum(Math.max(1,currentPage-1))
    }

    const goNext = (e) => {
        e.preventDefault()
        setPageNum(Math.min(numPages,currentPage+1))
    }

    const prevDisabled = currentPage === 1
    const nextDisabled = currentPage === numPages

    return (
        <div className="page-select">
            <AnchorButton className="page-select-bookend" disabled={prevDisabled} onClick={goPrev}>{"<   Previous"}</AnchorButton>
            {pageNumList}
            <AnchorButton className="page-select-bookend" disabled={nextDisabled} onClick={goNext}>{"Next   >"}</AnchorButton>
        </div>
    )
}

PageSelect.propTypes = {
    numPages: PropTypes.number.isRequired,
    setPageNum: PropTypes.func.isRequired,
    currentPage: PropTypes.number.isRequired
}

export default PageSelect