import GoogleTagManager from 'react-gtm-module';

export function initialize(gtmId) {
    GoogleTagManager.initialize({
        gtmId
    });
}

export function dataLayerPush(data) {
    GoogleTagManager.dataLayer({
        dataLayer: data
    })
}

export default {
    initialize,
    dataLayerPush
}