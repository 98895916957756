import moment from 'moment';

/**
 * 
 * @param {String|null} locked_until 
 */
export default function recentlyOrderedLock(locked_until) {
    locked_until = new Date(locked_until);
    if (locked_until.toString() === "Invalid Date") {
        return null;
    } else if (locked_until.getTime() <= Date.now()) {
        return null;
    } else return moment(locked_until).fromNow();
}