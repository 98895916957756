import React from 'react';
import TitledTemplate from '../../components/templates/titled/TitledTemplate';
import PropTypes from 'prop-types';
import ResourceDisplay from '../../components/ResourceDisplay/ResourceDisplay';
import useApiResponse from '../../services/hooks/asyncblock/apiresponse/useApiResponse';
import useAsyncBlock from '../../services/hooks/asyncblock/useAsyncBlock';
import ValidateEmail from '../../services/helpers/validators/ValidateEmail';
import EditableTextDisplay from '../../components/editable_display/EditableTextDisplay';
import DestructiveButton from '../../components/actions/buttons/destructive/DestructiveButton';
import SecondaryButton from '../../components/actions/buttons/secondary/SecondaryButton';
import './ProfilePage.css';
import { NoRenderErrorBoundary } from '../../services/bugsnag/bugsnag';

function ProfilePage(props) {
    const {
        status: loadProfileStatus,
        load: reloadProfile,
        localCopy: state,
        assignLocalCopy: dispatch
    } = useApiResponse(props.getProfile);

    const {
        status: deleteStatus,
        load: _deleteProfile
    } = useAsyncBlock(props.deleteProfile);
    const clickDelete = async () => {
        if (window.confirm("Are you sure you want to deactivate your account? This cannot be undone.")) {
            _deleteProfile();
        }
    }

    const updateName = async (name) => {
        if (!name) throw new Error("Please provide a value for name.");
        const names = name.split(' ');
        let firstName, lastName;
        if (names.length > 1) {
            firstName = names.slice(0, names.length - 1).join(' ');
            lastName = names[names.length - 1];
        } else {
            firstName = names[0];
            lastName = "";
        }

        await props.updateProfile({firstName, lastName});

        dispatch({ firstName, lastName });
    }

    const updateEmail = async (email) => {
        if (!ValidateEmail(email)) throw new Error("Please provide a valid email address.");
        await props.updateProfile({email});

        dispatch({ email });
    }


    const name = (profile) => {
        const { firstName, lastName } = profile;
        return (firstName && lastName) ? `${firstName} ${lastName}` : '';
    }

    return (
        <TitledTemplate title="Profile">
            <ResourceDisplay
                loading={loadProfileStatus.loading}
                error={loadProfileStatus.error}
                result={state}
                reload={reloadProfile}
            >{
                (profile) => {
                    return (
                        <div>
                            <div className="profile-card">
                                <img alt="" src={"https://dr2ucvwr1tnfi.cloudfront.net/" + profile._id} />
                                <div className="profile-card-fields">
                                    <div className="field">
                                        <label htmlFor="profile-name">Name</label>
                                        <EditableTextDisplay
                                            id="profile-name"
                                            update={updateName}
                                            placeholder="Jane Doe"
                                        >{name(profile)}
                                        </EditableTextDisplay>
                                    </div>
                                    <div className="field">
                                        <label htmlFor="profile-email">Email</label>
                                        <EditableTextDisplay
                                            id="profile-email"
                                            update={updateEmail}
                                        >
                                            {profile.email}
                                        </EditableTextDisplay>
                                    </div>
                                    <div className="buttons">
                                        <SecondaryButton onClick={props.logout}>Logout</SecondaryButton>
                                        <DestructiveButton
                                            onClick={clickDelete}
                                            loading={deleteStatus.loading}
                                            error={deleteStatus.error}
                                        >Deactivate Account</DestructiveButton>
                                    </div>
                                </div>
                            </div>
                            <NoRenderErrorBoundary>
                                {props.footer}
                            </NoRenderErrorBoundary>
                        </div>
                    )
                }
            }</ResourceDisplay>
        </TitledTemplate>
    )
}

ProfilePage.propTypes = {
    getProfile: PropTypes.func.isRequired,
    updateProfile: PropTypes.func.isRequired
}

export default ProfilePage;