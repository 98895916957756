import { useState } from 'react';

function useSession() {
    const [session, setSession] = useState(sessionFromStorage);
    const logout = () => {
        clearSession();
        setSession(null);
    }

    const login = ( profile_id, token, email, admin, newUser ) => {
        saveSession({ profile_id, token, email, admin });
        setSession({ profile_id, token, email, admin, newUser });
    }

    return { 
        profile_id: session && session.profile_id,
        token: session && session.token,
        login,
        logout,
        email: session && session.email,
        admin: session && session.admin,
        loggedIn: session && session.profile_id && session.token,
        newUser: session && session.newUser
    };
}

function sessionFromStorage() {
    try {
        let session = localStorage.getItem('session');
        if (session) {
            const { profile_id, token, email, admin } = JSON.parse(session);
            return { profile_id, token, email, admin };
        }
    } catch (err) {
        console.error(err);
    }
}

function saveSession({ profile_id, token, email, admin }) {
    const session = JSON.stringify({ profile_id, token, email, admin });
    try {
        localStorage.setItem('session', session);
    } catch (err) {
        console.error(err);
    }
}

function clearSession() {
    try {
        localStorage.removeItem('session');
    } catch (err) { console.error(err); }
}

export default useSession;