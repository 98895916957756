import React from 'react';
import PropTypes from 'prop-types';
import Loading from '../Indicator/Loading/Loading';
import Error from '../Indicator/Error/Error';
import './ResourceDisplay.css';

function ResourceDisplay(props) {
    if (props.loading) {
        return props.loadingComponent();
    } else if (props.error) {
        return props.errorComponent({retry: props.reload && (() => props.reload(false)), message: props.errorMessage});
    } else {
        return (
            <div className="resource-display">
                { props.children(props.result) }
            </div>
        );
    }
}

ResourceDisplay.defaultProps = {
    errorComponent: Error,
    loadingComponent: Loading
}

ResourceDisplay.propTypes = {
    children: PropTypes.elementType.isRequired,
    fetchResources: PropTypes.func,
    errorComponent: PropTypes.elementType.isRequired,
    loadingComponent: PropTypes.elementType.isRequired,
    errorMessage: PropTypes.string
}

export default ResourceDisplay;