import React from 'react'
import { DefaultErrorBoundary, NoRenderErrorBoundary } from '../../services/bugsnag/bugsnag'
import './History.css'
import TabSelector from '../../components/tabselector/TabSelector'
import Transactions from '../../modules/transactions/Transactions'
import Stories from '../../modules/stories/Stories'
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom'

function History({loadArchivedDeals, earningsWheel, footer, loadTransactions}) {

    const match = useRouteMatch()

    return (
        <div className="history">
            <div className="white-card">
                <DefaultErrorBoundary>
                    {earningsWheel()}
                </DefaultErrorBoundary>
            </div>
            {(() => {
                    return (
                        <div className="white-card">
                            <div className="tab-row">
                                <TabSelector to={`${match.url}/stories`}>Stories</TabSelector>
                                <TabSelector to={`${match.url}/transactions`}>Transactions</TabSelector>
                            </div>
                            <DefaultErrorBoundary>
                                <Switch>
                                    <Route path={`${match.path}/transactions`}>
                                        <Transactions
                                            loadTransactions={loadTransactions}
                                         />
                                    </Route>
                                    <Route path={`${match.path}/stories`}>
                                        <Stories
                                            loadDeals={loadArchivedDeals}
                                        />
                                    </Route>
                                    <Redirect to={`${match.path}/stories`} />
                                </Switch>
                            </DefaultErrorBoundary>
                        </div>
                    )
                
            })()}
            <NoRenderErrorBoundary>
                {footer}
            </NoRenderErrorBoundary>
        </div>
    )
}

export default History