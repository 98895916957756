import React, { useState, useEffect } from 'react'
import AnchorButton from '../../components/actions/buttons/anchor/AnchorButton'
import { useHistory } from 'react-router-dom'
import { notify } from '../../services/bugsnag/bugsnag'
import useQueryParams from '../../services/hooks/useQueryParams'
import validateEmail from '../../services/helpers/validators/ValidateEmail'
import guruserver from '../../services/apis/guruserver'
import './Unsubscribe.css'

function Unsubscribe(props) {
    const history = useHistory()
    const queryParams = useQueryParams()
    const [status, setStatus] = useState("Please wait..")

    useEffect(() => {
        if (queryParams.email && validateEmail(queryParams.email)) {
            guruserver.email.unsubscribe(queryParams.email).then(()=>{
                setStatus("Success. The email address " + queryParams.email + " has been removed from our mailing list.")
            }).catch(err=> {
                notify(err)
                setStatus("An unexpected error occurred. Please contact development@guru.club for help.")
            })
        } else {
            setStatus("The link you followed seems to be incorrect. Please contact development@guru.club for help.")
        }
    }, [queryParams.email])

    return (
        <div className="unsubscribe-mailing-list">
            <h3>{status}</h3>
            <AnchorButton onClick={e=>history.push({pathname:"/"})}>Go Home</AnchorButton>
        </div>
    )
}

export default Unsubscribe